.responsive-image {
	position: relative;
    width: 100%;

    // border: 1px solid #f0c;


    &.backend-image {
    	background-size: cover;
    }


	.image-dimension { 
		
	}

	img {
	    width: 100%;
	    height: auto;
	    position: absolute;
	    top: 0;

	    transition: all 0.5s ease-in-out;
	    opacity: 0;

	    &.loaded {
	    	opacity: 1;
	    }
	}


	&.parallax {
		overflow: hidden;

		img {
			width: 140%;
			left: -20%;
		}
	}
}


.background-image {
	position: relative;
    width: 100%;

    // border: 1px solid #f0c;
    overflow: hidden;

    .background-image-inner {

    	transition: opacity 0.5s ease-in-out;
	    opacity: 0;

	    &[data-was-processed="true"] {
	    	opacity: 1;
	    }


    	position: absolute;
	    top: -10%;

    	width: 100%;
    	height: 120%;

    	background-position: center top;
	    background-repeat: no-repeat;
	    background-size: cover;

	    transform: translate3d(0, 0, 0);

    }
}

.no-image {
	background: #32373c;
	color: #23282d;

	color: #b4b9be;
    color: rgba(240,245,250,.4);
    font-style: italic;

	padding: 40px;
	// text-align: center;

	position: relative;

	&::after {
		content: "";
		display: inline-block;
		width: 100%;
		padding-top: 20%;
	}
}