@import "../../css/_config.scss";

.help {
	border-left: 1px solid #f0c;
	border-right: 1px solid #f0c;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    width: 1120px;
   	max-width: 1120px;


   	@include maxwidth(xl) {
   		width: 942px;
   	}
   	@include maxwidth(lg) {
   		width: 702px;
   	}
   	@include maxwidth(md) {
   		width: 522px;
   	}
   	@include maxwidth(sm) {
   		// width: 502px;
   		// width: 100%;
   		// box-sizing: border-box;
   		// margin-left: 20px;
   		// margin-right: 20px;
   		width: initial;
   		transform: none;
   		left: 18px;
   		right: 18px;
   		background: rgba(#f0c, 0.4);
   	}
   	// @include maxwidth(xs) {
   	// 	width: 100%;
   	// }
}

header {



	.logo-wrapper {
		width: 250px;
		margin: 30px 20px;
		margin-left: 0;
	}


	.logo-col {
		// @include make-col-ready();

		// @include media-breakpoint-up(sm) { @include make-col(7); }
		// @include media-breakpoint-up(lg) { @include make-col(7); }
	}

	.container {
		.row {
			&.border-bottom {
				&::after {
					margin-left: 10px;
					margin-right: 10px;
					box-sizing: border-box;

					// position: absolute;
					// left: 0;
					// right: 0;
					// bottom: 0;
					// width: initial;
				}
			}
		}

		.border-top {
			&::before {
				margin-left: 0px;
				margin-right: 0px;
			}
		}
	}



	#navigation-toggle {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		right: 8px;

		padding: 0;
		width: 30px;
		height: 30px;
		border: none;
		background: none;
		outline: none;

		cursor: pointer;

		.icon {
			position: absolute;
			top: 0;
			width: 30px;
			height: 30px;
			transition: all 0.15s ease-in;

			&.menu-close {
				opacity: 0;
				visibility: hidden;

				svg {
					fill: #fff;
				}
			}
		}


		@include maxwidth(md) {
			// right: 14px;
		}

		@include maxwidth(sm) {
			// right: 24px;
			right: 10px;
		}
	}



	#search {
		position: absolute;
		top: 38px;
		
		right: 6px;

		padding: 0;
		width: 30px;
		height: 30px;
		border: none;
		background: none;
		outline: none;

		cursor: pointer;

		.icon {
			position: absolute;
			top: 0;
			width: 30px;
			height: 30px;
			transition: all 0.15s ease-in;

			&.menu-close {
				opacity: 0;
				visibility: hidden;

				svg {
					fill: #fff;
				}
			}
		}
	}


	#navigation {
		font-family: 'FuturaPT-Medium';
		font-size: 14px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.28px;

		margin-left: 6px;

		a {
			text-transform: uppercase;
			color: #000;
			text-decoration: none;

			position: relative;

			transition: all 0.15s ease-in;
			// padding-left: 20px;

			&::before {
				content: "";
				display: inline-block;

				transition: all 0.15s ease-in;

				position: absolute;
				transform-origin: top left;
				transform: rotateY(-90deg);

				// left: -15px;
				left: 0;
				top: 0px;
				width: 15px;
  				height: 13px;
  				background-repeat: no-repeat;
				background-image: url("../../images/triangle.svg");

				opacity: 0;
				visibility: hidden;
			}


			&[aria-current="page"],
			&:hover {
				opacity: 1;

				padding-left: 20px;

				&::before {
					opacity: 1;
					visibility: visible;
					left: 0px;
					transform: rotateY(0deg);
				}
			}

			&:visited,
			&:focus {
				text-transform: uppercase;
				color: #000;
				text-decoration: none;
			}
		}


		.navigation-inner {
			ul {
				// margin: 30px 0px;
				margin: 16px 0px;
				padding: 0;
				display: inline-block;
				width: 100%;

				li {
					width: 49%;
					margin: 7px 0px;
					padding: 0;
					display: inline-block;
				}

			}
		}
	}




}














#mobile-menu {
	// display: none;

	transition: all 0.15s ease-in;
	visibility: hidden;
	opacity: 0;

	z-index: 4;

	padding-top: 140px;
	
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.9);

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;



	.container {
	    padding-right: 0px;
		padding-left: 0px;


		@include maxwidth(sm) {
			padding-right: 10px;
			padding-left: 10px;
		}

		.row {

			&::before {
				display: inline-block;
				content: "";
				box-sizing: border-box;
				// position: absolute;
				// left: 20px;
				// right: 20px;
				width: 100%;
				margin-left: 20px;
				margin-right: 20px;
				border-top: 1px solid #fff;


				/*
				@include maxwidth(md) {
					// right: 14px;
					width: 100%;
					left: initial;
					right: initial;
				}

				@include maxwidth(sm) {
					// right: 24px;
					width: 100%;
					left: initial;
					right: initial;
				}
				*/
			}
		}	
	}



	ul {
		padding: 0;
		margin: 0;
		margin-left: 10px;
		margin-right: 10px;
		margin-top: 30px;
		list-style: none;



		li {
			list-style: none;
			padding: 0;
			margin: 0;
			margin-bottom: 14px;
		}
	}


	font-family: 'FuturaPT-Medium';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.32px;
	color: #ffffff;

	a {
		text-transform: uppercase;
		color: #fff;
		text-decoration: none;

		position: relative;

		transition: all 0.15s ease-in;
		// padding-left: 20px;


		&::before {
			content: "";
			display: inline-block;

			transition: all 0.15s ease-in;

			position: absolute;
			transform-origin: top left;
			transform: rotateY(-90deg);

			// left: -15px;
			left: 0;
			top: 0px;
			width: 15px;
				height: 13px;
				background-repeat: no-repeat;
			background-image: url("../../images/triangle-white.svg");

			opacity: 0;
			visibility: hidden;
		}


		&[aria-current="page"],
		&:hover {
			opacity: 1;

			padding-left: 20px;

			&::before {
				opacity: 1;
				visibility: visible;
				left: 0px;
				transform: rotateY(0deg);
			}
		}

		&:visited,
		&:focus {
			text-transform: uppercase;
			color: #fff;
			text-decoration: none;
		}
	}
}




form {


	input {
		font-size: 16px;
		padding: 6px 8px;

		border: none;
		border-bottom: 1px solid #000;
		background: none;
		width: 90%;
	}


	button {
		position: relative;
		// top: 50%;
		// transform: translate(0, -50%);
		// right: 6px;
		top: 8px;
		margin-left: 8px;


		padding: 0;
		width: 30px;
		height: 30px;
		border: none;
		background: none;
		outline: none;

		cursor: pointer;


		.icon {
			position: absolute;
			top: 0;
			width: 30px;
			height: 30px;
			transition: all 0.15s ease-in;

			&.menu-close {
				opacity: 0;
				visibility: hidden;

				svg {
					fill: #fff;
				}
			}
		}
	}
}

.search-container {
	background-color: #fff;
	background-color: rgba(#fff, 0.95);

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;

	transition: all 0.15s ease-in;
	visibility: hidden;
	opacity: 0;

	.inner-search {
		position:absolute;
		top: 50%;
		left: 50%;

		transform: translate(-50%,-50%);
	}
}

.search-result {
	.icon {
		width: 30px;
		display: inline-block;
		position: relative;
		top: 10px;
	}
}





body {
	#navigation-toggle {
		display: none;
	}


	&.search-is-open {
		.search-container {
			visibility: visible;
			opacity: 1;
		}
	}


	&.mobile-header {


		.search-container,
		#search {
			display: none;
		}

		#navigation-toggle {
			display: inline-block;
		}



		&.menu-is-open {

			header {
				position: fixed;
				width: 100%;
				z-index: 9;
				box-sizing: border-box;
				// margin-left: -8px;
				// margin-right: -8px;



				
				.row .col-7 {
					&.border-top {
						@include maxwidth(md) {
							padding-right: 0px;
							padding-left: 0px;
						}

						@include maxwidth(sm) {
							padding-right: 10px;
							padding-left: 10px;
						}
					}
				}



			}

			#mobile-menu {
				visibility: visible;
				opacity: 1;
			}


			.logo-wrapper {
				svg *{
					fill: #fff;
				}
			}

			#navigation-toggle {
				z-index: 9999;

				// @include maxwidth(sm) {
				// 	right: 10px;
				// }


				// @include maxwidth(lg) {
					right: 14px;
				// }
				@include maxwidth(sm) {
					right: 24px;
				}
				
				.icon {
					&.menu-open {
						opacity: 0;
						visibility: hidden;
					}

					&.menu-close {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

		nav {
			display: none;
		}


		header {


			.logo-wrapper {
				width: 180px;
				// margin-left: 0px;
				margin: 0;
			    z-index: 9;
    			position: relative;

				svg {
					* {
						transition: all 0.15s ease-in;
					}
				}
			}
			.border-bottom {
				&::after {
					display: none;
				}
			}
			.border-top {
				&::before {
					display: none;
				}
			}
		}
	}
}