$font_source: './../fonts';


/*@import url("//hello.myfonts.net/count/396bd3");*/

  
@font-face {
	font-family: 'FuturaPT-Book';
	src: 	url('#{$font_source}/FuturaPT/396BD3_0_0.eot');
	src: 	url('#{$font_source}/FuturaPT/396BD3_0_0.eot?#iefix') format('embedded-opentype'),
			url('#{$font_source}/FuturaPT/396BD3_0_0.woff2') format('woff2'),
			url('#{$font_source}/FuturaPT/396BD3_0_0.woff') format('woff'),
			url('#{$font_source}/FuturaPT/396BD3_0_0.ttf') format('truetype');
}

@font-face {
	font-family: 'FuturaPT-Medium';
	src: 	url('#{$font_source}/FuturaPT/396BD3_1_0.eot');
	src: 	url('#{$font_source}/FuturaPT/396BD3_1_0.eot?#iefix') format('embedded-opentype'),
			url('#{$font_source}/FuturaPT/396BD3_1_0.woff2') format('woff2'),
			url('#{$font_source}/FuturaPT/396BD3_1_0.woff') format('woff'),
			url('#{$font_source}/FuturaPT/396BD3_1_0.ttf') format('truetype');
}



body {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
}



h2 {
	font-size: 48px;
	font-family: 'FuturaPT-Medium';
	text-transform: uppercase;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.55;
	letter-spacing: 2.4px;
}

h3 {
	margin: 0;
}