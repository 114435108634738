.hidden {
	display: none;
}
#cookie-popup {
	
	// font-size: 13px;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.9);
	text-align: center;
	color: #fff;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	line-height: 20px;
	padding: 20px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
	
	a {
		color: #ffffff;
		border-bottom: 1px solid #fff;
		
		&:hover {
			opacity: 0.65;
			text-transform: none;
		}
	}

	button {
		border: 1px solid #fff;
		color: #fff;
		background: none;
		transition: all 0.15s ease-in;
		cursor: pointer;
		padding: 4px 8px;

		&:hover {
			background: #fff;
			color: #000;
		}
	}
}