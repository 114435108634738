body {
	font-family: 'FuturaPT-Book', sans-serif;
	
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.52;
	letter-spacing: 0.7px;


}

.background-image {
	margin-bottom: 20px;

	.no-parallax {
		background-size: contain;
	}
}


.page-wrapper {
	margin: 40px 0;
}


.row {
	// background: rgba(#ff00cc,0.2);
}

.col {
	// background: rgba(#ffcc00,0.5);
}

.inner-col {
	// background: rgba(#ccff00,0.5);
}

.search-container {
	.search-close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 30px;
		height: 30px;
		transition: all 0.15s ease-in;
		cursor: pointer;

		&:hover {
			opacity: 0.35;
		}
	}
}


// @import "../components/header/";

.border-top {
	&::before {
		content: "";
		display: inline-block;
		margin-left: 4px;
		margin-right: 4px;
		width: 100%;
		box-sizing: border-box;
		border-top: 1px solid #000;
	}
}

.border-bottom {
	&::after {
		content: "";
		display: inline-block;
		margin-left: 4px;
		margin-right: 4px;
		width: 100%;
		box-sizing: border-box;
		border-bottom: 1px solid #000;
	}
}



.wp-block {
	width: 100%;
}


.arrow-button {
	display: inline-block;
	width: 53px;
  	height: 35px;
	background-repeat: no-repeat;
	background-image: url("../images/arrow-button.svg");
	position: relative;
	top: 2px;
}






.hero-image {
	.hero-image-wrapper {
		position: relative;

		.title-wrapper {
			top: 0px;
			// width: 100%;
			height: 100%;
		
		    right: -4px;
    		left: -4px;

			position: absolute;
		}


		@include maxwidth(lg) {



			
				

				margin-bottom: 6rem;;

				.title-wrapper {
					// position: relative;

					top: -5rem;
					
    				

					
						h2 {

							font-size: 38px;
							font-weight: 500;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.4;
							letter-spacing: 1.9px;

							padding-left: 30px;
							padding-right: 30px;

							position: absolute;

						}

				}
			}


	}
}


[data-layout="left-small"] {
	.image-col {
		padding-right: 20px;
		margin-bottom: 60px;

		@include maxwidth(lg) {
			padding-right: 0px;
		}
	}
}


[data-layout="left-big"] {
	.text-col {
		margin-top: 60px;
		margin-bottom: 60px;
		

		@include maxwidth(lg) {
			margin-bottom: 0px;	
		}
	}
}

.service-tile {
	margin-bottom: 40px;

	.background-image {
		margin-bottom: 20px;
	}
}

.surfaces {
	.background-image {
		margin-bottom: 0;
	}
}


a {
	color: #000;
	text-decoration: none;

	transition: all 0.15s ease-in;

	&:hover {
		opacity: 0.5;
	}

	&:visited,
	&:focus {
		text-transform: uppercase;
		color: #000;
		text-decoration: none;
	}
}


footer {
	border-top: 1px solid #000;
	padding: 30px 0;

	line-height: 1.52;
  	letter-spacing: 0.7px;

	h4 {
		margin: 0;
		text-transform: uppercase;
	}

	p {
		margin: 0;
	}


	.row {
		.inner-col {
    		margin-left: -10px;
    		margin-right: -10px;
    	}
	}


	@include maxwidth(lg) {
		.row {
		    .col {
		    	flex-basis: initial;
		    	width: 100%;
		    	margin-bottom: 30px;

		    	// padding-left: 0;
		    	// padding-right: 0;
		    }
		}
	}
}


